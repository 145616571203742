const {
	DATE_ADD,
	DATE_SUBTRACT,
	DATE_START_OF,
	DATE_END_OF,
	DATE_FORMAT,
	NUMBER_FORMAT,
	DURATION_FORMAT,
	DURATION_ADD,
	DURATION_SUBTRACT,
	INVERT_VALUE,
	ADD_SELECTION,
	REMOVE_SELECTION,
	ADD_CONTEXT,
	REMOVE_CONTEXT,
	ADD_SELECTED,
	REMOVE_SELECTED,
	INTEGER_SUBTRACT,
	INTEGER_ADD,
	FLOAT_ADD,
	FLOAT_SUBTRACT,
	REFERENCE_ADD_CONTEXT,
	REFERENCE_REMOVE_CONTEXT,
	REFERENCE_ADD_SELECTED,
	REFERENCE_REMOVE_SELECTED,
	REFERENCE_ADD_SELECTION,
	REFERENCE_REMOVE_SELECTION,
} = require('../enums/e_ValueProcessorOperation')

const {
	DEFAULT,
	TIME,
	SHORT_DATE,
	DATE,
	LONG_DATE,
	FROM_NOW,
	TO_NOW,
	CALENDAR_TIME,
	CUSTOM,
} = require('../enums/e_DateFormat')
const e_DurationFormat = require('../enums/e_DurationFormat')
const isNil = require('lodash/isNil')
const isPlainObject = require('lodash/isPlainObject')
const isArray = require('lodash/isArray')

const formatNumber = require('./formatNumber')
const { e_NumberFormat } = require('../enums/e_PropertyTypes')
const e_ValueProcessorOperation = require('../enums/e_ValueProcessorOperation')

const getDayjsDate = (dayjs, value, timeZone) => (timeZone ? dayjs(value).tz(timeZone) : dayjs(value))
const getDayjsDuration = (dayjs, value) => dayjs.duration(value)
const getDataFromDataValue = (dataValue, options) => {
	if (options.getDataFromDataValue) {
		return options.getDataFromDataValue(dataValue)
	} else {
		console.error('ValueProcessor: Missing method getDataFromDataValue')
		if (!isPlainObject(dataValue)) return dataValue // Constant

		return undefined
	}
}
const p_getDataFromDataValue = (dataValue, options) => {
	if (options.p_getDataFromDataValue) {
		return options.p_getDataFromDataValue(dataValue)
	} else {
		console.error('ValueProcessor: Missing method p_getDataFromDataValue')
		if (!isPlainObject(dataValue)) return dataValue // Constant

		return undefined
	}
}

const _processDateAdd = ({ dayjs, value, dateAbsoluteNumber, dateShiftAbsoluteUnit }) => {
	if (isNil(dateShiftAbsoluteUnit)) return value
	if (!dateAbsoluteNumber) return value

	return getDayjsDate(dayjs, value).add(dateAbsoluteNumber, dateShiftAbsoluteUnit).toJSON()
}

const processDateAdd = (dayjs, value, processDescription, options) => {
	const dateAbsoluteNumber = getDataFromDataValue(processDescription.dateAbsoluteNumber, options)
	return _processDateAdd({
		dayjs,
		value,
		dateAbsoluteNumber,
		dateShiftAbsoluteUnit: processDescription.dateShiftAbsoluteUnit,
	})
}

const p_processDateAdd = async (dayjs, value, processDescription, options) => {
	const dateAbsoluteNumber = await p_getDataFromDataValue(processDescription.dateAbsoluteNumber, options)
	return _processDateAdd({
		dayjs,
		value,
		dateAbsoluteNumber,
		dateShiftAbsoluteUnit: processDescription.dateShiftAbsoluteUnit,
	})
}

const _processDateSubtract = ({ dayjs, value, dateShiftAbsoluteUnit, dateAbsoluteNumber }) => {
	if (isNil(dateShiftAbsoluteUnit)) return value
	if (!dateAbsoluteNumber) return value

	return getDayjsDate(dayjs, value).subtract(dateAbsoluteNumber, dateShiftAbsoluteUnit).toJSON()
}

const processDateSubtract = (dayjs, value, processDescription, options) => {
	const dateAbsoluteNumber = getDataFromDataValue(processDescription.dateAbsoluteNumber, options)
	return _processDateSubtract({
		dayjs,
		value,
		dateAbsoluteNumber,
		dateShiftAbsoluteUnit: processDescription.dateShiftAbsoluteUnit,
	})
}

const p_processDateSubtract = async (dayjs, value, processDescription, options) => {
	const dateAbsoluteNumber = await p_getDataFromDataValue(processDescription.dateAbsoluteNumber, options)
	return _processDateSubtract({
		dayjs,
		value,
		options,
		dateAbsoluteNumber,
		dateShiftAbsoluteUnit: processDescription.dateShiftAbsoluteUnit,
	})
}

const processDateStartOf = (dayjs, value, processDescription, timeZone) => {
	if (isNil(processDescription.dateShiftRelativeUnit)) return value

	return getDayjsDate(dayjs, value, timeZone).startOf(processDescription.dateShiftRelativeUnit).toJSON()
}

const processDateEndOf = (dayjs, value, processDescription, timeZone) => {
	if (isNil(processDescription.dateShiftRelativeUnit)) return value

	return getDayjsDate(dayjs, value, timeZone).endOf(processDescription.dateShiftRelativeUnit).toJSON()
}

const processDateFormat = (dayjs, value, processDescription, options) => {
	if (options.skipDateFormat) return value

	const timeZone = options.timeZone
	switch (processDescription.dateFormat) {
		case TIME:
			return getDayjsDate(dayjs, value, timeZone).format('LT')
		case SHORT_DATE:
			return getDayjsDate(dayjs, value, timeZone).format('L')
		case DATE:
			return getDayjsDate(dayjs, value, timeZone).format('LL')
		case LONG_DATE:
			return getDayjsDate(dayjs, value, timeZone).format('LLLL')
		case FROM_NOW:
			return getDayjsDate(dayjs, value).fromNow() // relative to utc, no need for timezone info
		case TO_NOW:
			return getDayjsDate(dayjs, value).toNow() // relative to utc, no need for timezone info
		case CALENDAR_TIME:
			return getDayjsDate(dayjs, value, timeZone).calendar() // relative to utc, no need for timezone info
		case CUSTOM:
			return getDayjsDate(dayjs, value, timeZone).format(processDescription.customDateFormat)
		case DEFAULT:
		default:
			return getDayjsDate(dayjs, value, timeZone).format()
	}
}

const processNumberFormat = (value, processDescription, options) => {
	if (options.skipNumberFormat) return value

	return formatNumber({
		number: value,
		locale: options.numberFormat,
		style: processDescription.numberFormat || e_NumberFormat.DECIMAL,
		currency: processDescription.currency || options.currency,
		decimalPlaces: processDescription.decimalPlaces,
		thousandSeparator: !processDescription.hideThousandSeparator,
	})
}

const processDurationFormat = (dayjs, value, processDescription, options) => {
	if (options.skipDurationFormat) return value

	switch (processDescription.durationFormat) {
		case e_DurationFormat.HUMAN:
			return getDayjsDuration(dayjs, value).humanize()
		case e_DurationFormat.YEARS:
			return getDayjsDuration(dayjs, value).years()
		case e_DurationFormat.MONTHS:
			return getDayjsDuration(dayjs, value).months()
		case e_DurationFormat.WEEKS:
			return getDayjsDuration(dayjs, value).weeks()
		case e_DurationFormat.DAYS:
			return getDayjsDuration(dayjs, value).days()
		case e_DurationFormat.HOURS:
			return getDayjsDuration(dayjs, value).hours()
		case e_DurationFormat.MINUTES:
			return getDayjsDuration(dayjs, value).minutes()
		case e_DurationFormat.SECONDS:
			return getDayjsDuration(dayjs, value).seconds()
		case e_DurationFormat.MILLISECONDS:
			return getDayjsDuration(dayjs, value).milliseconds()
		case e_DurationFormat.DEFAULT:
		default:
			return getDayjsDuration(dayjs, value).toISOString()
	}
}

const _processDurationAdd = ({ dayjs, value, dateAbsoluteNumber, dateShiftAbsoluteUnit }) => {
	if (isNil(dateShiftAbsoluteUnit)) return value
	if (!dateAbsoluteNumber) return value

	return getDayjsDuration(dayjs, value).add(dateAbsoluteNumber, dateShiftAbsoluteUnit).asMilliseconds()
}

const processDurationAdd = (dayjs, value, processDescription, options) => {
	const dateAbsoluteNumber = getDataFromDataValue(processDescription.dateAbsoluteNumber, options)
	return _processDurationAdd({
		dayjs,
		value,
		dateAbsoluteNumber,
		dateShiftAbsoluteUnit: processDescription.dateShiftAbsoluteUnit,
	})
}

const p_processDurationAdd = async (dayjs, value, processDescription, options) => {
	const dateAbsoluteNumber = await p_getDataFromDataValue(processDescription.dateAbsoluteNumber, options)
	return _processDurationAdd({
		dayjs,
		value,
		dateAbsoluteNumber,
		dateShiftAbsoluteUnit: processDescription.dateShiftAbsoluteUnit,
	})
}

const _processDurationSubtract = ({ dayjs, value, dateShiftAbsoluteUnit, dateAbsoluteNumber }) => {
	if (isNil(dateShiftAbsoluteUnit)) return value
	if (!dateAbsoluteNumber) return value

	return getDayjsDuration(dayjs, value).subtract(dateAbsoluteNumber, dateShiftAbsoluteUnit).asMilliseconds()
}

const processDurationSubtract = (dayjs, value, processDescription, options) => {
	const dateAbsoluteNumber = getDataFromDataValue(processDescription.dateAbsoluteNumber, options)
	return _processDurationSubtract({
		dayjs,
		value,
		dateAbsoluteNumber,
		dateShiftAbsoluteUnit: processDescription.dateShiftAbsoluteUnit,
	})
}

const p_processDurationSubtract = async (dayjs, value, processDescription, options) => {
	const dateAbsoluteNumber = await p_getDataFromDataValue(processDescription.dateAbsoluteNumber, options)
	return _processDurationSubtract({
		dayjs,
		value,
		options,
		dateAbsoluteNumber,
		dateShiftAbsoluteUnit: processDescription.dateShiftAbsoluteUnit,
	})
}

const _processIntegerAdd = ({ value, absoluteNumber }) => value + parseInt(absoluteNumber || 0)

const processIntegerAdd = (value, processDescription, options) => {
	const absoluteNumber = getDataFromDataValue(processDescription.absoluteNumber, options)
	return _processIntegerAdd({ value, absoluteNumber })
}
const p_processIntegerAdd = async (value, processDescription, options) => {
	const absoluteNumber = await p_getDataFromDataValue(processDescription.absoluteNumber, options)
	return _processIntegerAdd({ value, absoluteNumber })
}

const _processIntegerSubtract = ({ value, absoluteNumber }) => value - parseInt(absoluteNumber || 0)

const processIntegerSubtract = (value, processDescription, options) => {
	const absoluteNumber = getDataFromDataValue(processDescription.absoluteNumber, options)
	return _processIntegerSubtract({ value, absoluteNumber })
}
const p_processIntegerSubtract = async (value, processDescription, options) => {
	const absoluteNumber = await p_getDataFromDataValue(processDescription.absoluteNumber, options)
	return _processIntegerSubtract({ value, absoluteNumber })
}

const _processFloatAdd = ({ value, absoluteNumber }) => value + parseFloat(absoluteNumber || 0)

const processFloatAdd = (value, processDescription, options) => {
	const absoluteNumber = getDataFromDataValue(processDescription.absoluteNumber, options)
	return _processFloatAdd({ value, absoluteNumber })
}
const p_processFloatAdd = async (value, processDescription, options) => {
	const absoluteNumber = await p_getDataFromDataValue(processDescription.absoluteNumber, options)
	return _processFloatAdd({ value, absoluteNumber })
}

const _processFloatSubtract = ({ value, absoluteNumber }) => value - parseFloat(absoluteNumber || 0)

const processFloatSubtract = (value, processDescription, options) => {
	const absoluteNumber = getDataFromDataValue(processDescription.absoluteNumber, options)
	return _processFloatSubtract({ value, absoluteNumber })
}
const p_processFloatSubtract = async (value, processDescription, options) => {
	const absoluteNumber = await p_getDataFromDataValue(processDescription.absoluteNumber, options)
	return _processFloatSubtract({ value, absoluteNumber })
}

const _addContext = (value, selection = []) => {
	const val = value?.length ? value : []
	return [...new Set([...val, ...selection.map((values) => values.map((item) => item.enum_value)).flat()])]
}
const addContext = (value, options) => {
	if (options.enumContextDataToAdd && Object.values(options.enumContextDataToAdd).length) {
		return _addContext(value, Object.values(options.enumContextDataToAdd))
	} else {
		return value
	}
}

const p_addContext = async (value, options) => {
	if (options.enumContextDataToAdd && Object.values(options.enumContextDataToAdd).length) {
		const selection = await Promise.all(Object.values(options.enumContextDataToAdd))
		return _addContext(value, selection)
	} else {
		return value
	}
}

const _removeContext = (value, selection = []) => {
	return value?.filter(
		(item) =>
			!selection
				.map((values) => values.map((item) => item.enum_value))
				.flat()
				.includes(item)
	)
}

const removeContext = (value, options) => {
	if (options.enumContextDataToRemove && Object.values(options.enumContextDataToRemove).length) {
		return _removeContext(value, Object.values(options.enumContextDataToRemove))
	} else {
		return value
	}
}

const p_removeContext = async (value, options) => {
	if (options.enumContextDataToRemove && Object.values(options.enumContextDataToRemove).length) {
		const selection = await Promise.all(Object.values(options.enumContextDataToRemove))
		return _removeContext(value, selection)
	} else {
		return value
	}
}

const _addSelected = (value, selection = []) => {
	const val = value?.length ? value : []
	return [...new Set([...val, ...selection.map((values) => values.map((item) => item.enum_value)).flat()])]
}

const addSelected = (value, options) => {
	if (options.enumSelectionDataToAdd && Object.values(options.enumSelectionDataToAdd).length) {
		return _addSelected(value, Object.values(options.enumSelectionDataToAdd))
	} else {
		return value
	}
}

const p_addSelected = async (value, options) => {
	if (options.enumSelectionDataToAdd && Object.values(options.enumSelectionDataToAdd).length) {
		const selection = await Promise.all(Object.values(options.enumSelectionDataToAdd))
		return _addSelected(value, selection)
	} else {
		return value
	}
}

const _removeSelected = (value, selection = []) => {
	return value?.filter(
		(item) =>
			!selection
				.map((values) => values.map((item) => item.enum_value))
				.flat()
				.includes(item)
	)
}
const removeSelected = (value, options) => {
	if (options.enumSelectionDataToRemove && Object.values(options.enumSelectionDataToRemove).length) {
		return _removeSelected(value, Object.values(options.enumSelectionDataToRemove))
	} else {
		return value
	}
}

const p_removeSelected = async (value, options) => {
	if (options.enumSelectionDataToRemove && Object.values(options.enumSelectionDataToRemove).length) {
		const selection = await Promise.all(Object.values(options.enumSelectionDataToRemove))
		return _removeSelected(value, selection)
	} else {
		return value
	}
}

const _getSelectionDataValue = (processDescription) =>
	[e_ValueProcessorOperation.ADD_SELECTION, e_ValueProcessorOperation.REMOVE_SELECTION].includes(
		processDescription.operation
	)
		? processDescription.selection
		: processDescription.refSelection

const _addSelection = ({ value, selection }) => {
	if (isNil(value)) value = []
	if (isNil(selection)) selection = []
	if (!isArray(selection)) selection = [selection] // context object is not array
	return [...new Set([...value, ...selection])]
}
const addSelection = (value, processDescription, options) => {
	const selection = getDataFromDataValue(_getSelectionDataValue(processDescription), options) || []
	return _addSelection({ value, selection })
}
const p_addSelection = async (value, processDescription, options) => {
	const selection = (await p_getDataFromDataValue(_getSelectionDataValue(processDescription), options)) || []
	return _addSelection({ value, selection })
}

const _removeSelection = ({ value, selection }) => {
	if (isNil(value)) value = []
	if (isNil(selection)) selection = []
	if (!isArray(selection)) selection = [selection] // context object is not array
	return value.filter((item) => !selection.includes(item))
}
const removeSelection = (value, processDescription, options) => {
	const selection = getDataFromDataValue(_getSelectionDataValue(processDescription), options) || []
	return _removeSelection({ value, selection })
}
const p_removeSelection = async (value, processDescription, options) => {
	const selection = (await p_getDataFromDataValue(_getSelectionDataValue(processDescription), options)) || []
	return _removeSelection({ value, selection })
}

const _referenceAddContext = (value, selection = []) => {
	const val = value?.length ? value : []
	return [
		...new Set([
			...val,
			...selection
				.map((values) =>
					values?.map((item) => {
						return item?._id
					})
				)
				.flat(),
		]),
	]
}

const referenceAddContext = (value, options) => {
	if (options.referenceContextDataToAdd && Object.values(options.referenceContextDataToAdd).length) {
		return _referenceAddContext(value, Object.values(options.referenceContextDataToAdd))
	} else {
		return value
	}
}

const p_referenceAddContext = async (value, options) => {
	if (options.referenceContextDataToAdd && Object.values(options.referenceContextDataToAdd).length) {
		const selection = await Promise.all(Object.values(options.referenceContextDataToAdd))
		return _referenceAddContext(value, selection)
	} else {
		return value
	}
}

const _referenceRemoveContext = (value, selection = []) => {
	const lookup = selection.map((values) => values?.map((item) => item?._id)).flat()
	return value.filter((item) => !lookup.includes(item))
}

const referenceRemoveContext = (value, options) => {
	if (options.referenceContextDataToRemove && Object.values(options.referenceContextDataToRemove).length) {
		return _referenceRemoveContext(value, Object.values(options.referenceContextDataToRemove))
	} else {
		return value
	}
}

const p_referenceRemoveContext = async (value, options) => {
	if (options.referenceContextDataToRemove && Object.values(options.referenceContextDataToRemove).length) {
		const selection = await Promise.all(Object.values(options.referenceContextDataToRemove))
		return _referenceRemoveContext(value, selection)
	} else {
		return value
	}
}

const _referenceAddSelected = (value, selection = []) => {
	const val = value?.length ? value : []
	return [
		...new Set([
			...val,
			...selection
				.map((values) =>
					values?.map((item) => {
						return item?._id
					})
				)
				.flat(),
		]),
	]
}

const referenceAddSelected = (value, options) => {
	if (options.referenceSelectedDataToAdd && Object.values(options.referenceSelectedDataToAdd).length) {
		return _referenceAddSelected(value, Object.values(options.referenceSelectedDataToAdd))
	} else {
		return value
	}
}

const p_referenceAddSelected = async (value, options) => {
	if (options.referenceSelectedDataToAdd && Object.values(options.referenceSelectedDataToAdd).length) {
		const selection = await Promise.all(Object.values(options.referenceSelectedDataToAdd))
		return _referenceAddSelected(value, selection)
	} else {
		return value
	}
}

const _referenceRemoveSelected = (value, selection = []) => {
	const lookup = selection.map((values) => values?.map((item) => item?._id)).flat()
	return value.filter((item) => !lookup.includes(item))
}

const referenceRemoveSelected = (value, options) => {
	if (options.referenceSelectedDataToRemove && Object.values(options.referenceSelectedDataToRemove).length) {
		return _referenceRemoveSelected(value, Object.values(options.referenceSelectedDataToRemove))
	} else {
		return value
	}
}

const p_referenceRemoveSelected = async (value, options) => {
	if (options.referenceSelectedDataToRemove && Object.values(options.referenceSelectedDataToRemove).length) {
		const selection = await Promise.all(Object.values(options.referenceSelectedDataToRemove))
		return _referenceRemoveSelected(value, selection)
	} else {
		return value
	}
}

const singleValueProcessor = (dayjs, options = {}, value, processDescription) => {
	switch (processDescription.operation) {
		case DATE_ADD:
			return processDateAdd(dayjs, value, processDescription, options)
		case DATE_SUBTRACT:
			return processDateSubtract(dayjs, value, processDescription, options)
		case DATE_START_OF:
			return processDateStartOf(dayjs, value, processDescription, options.timeZone)
		case DATE_END_OF:
			return processDateEndOf(dayjs, value, processDescription, options.timeZone)
		case DATE_FORMAT:
			return processDateFormat(dayjs, value, processDescription, options)
		case NUMBER_FORMAT:
			return processNumberFormat(value, processDescription, options)

		case DURATION_FORMAT:
			return processDurationFormat(dayjs, value, processDescription, options)
		case DURATION_ADD:
			return processDurationAdd(dayjs, value, processDescription, options)
		case DURATION_SUBTRACT:
			return processDurationSubtract(dayjs, value, processDescription, options)

		case INVERT_VALUE:
			return !value

		case INTEGER_ADD:
			return processIntegerAdd(value, processDescription, options)
		case INTEGER_SUBTRACT:
			return processIntegerSubtract(value, processDescription, options)
		case FLOAT_ADD:
			return processFloatAdd(value, processDescription, options)
		case FLOAT_SUBTRACT:
			return processFloatSubtract(value, processDescription, options)

		case ADD_CONTEXT:
			return addContext(value, options)
		case REMOVE_CONTEXT:
			return removeContext(value, options)
		case ADD_SELECTED:
			return addSelected(value, options)
		case REMOVE_SELECTED:
			return removeSelected(value, options)
		case ADD_SELECTION:
		case REFERENCE_ADD_SELECTION:
			return addSelection(value, processDescription, options)
		case REMOVE_SELECTION:
		case REFERENCE_REMOVE_SELECTION:
			return removeSelection(value, processDescription, options)
		case REFERENCE_ADD_CONTEXT:
			return referenceAddContext(value, options)
		case REFERENCE_REMOVE_CONTEXT:
			return referenceRemoveContext(value, options)
		case REFERENCE_ADD_SELECTED:
			return referenceAddSelected(value, options)
		case REFERENCE_REMOVE_SELECTED:
			return referenceRemoveSelected(value, options)
	}
}

const p_singleValueProcessor = async (dayjs, options = {}, value, processDescription) => {
	switch (processDescription.operation) {
		case DATE_ADD:
			return p_processDateAdd(dayjs, value, processDescription, options)
		case DATE_SUBTRACT:
			return p_processDateSubtract(dayjs, value, processDescription, options)
		case DATE_START_OF:
			return processDateStartOf(dayjs, value, processDescription, options.timeZone)
		case DATE_END_OF:
			return processDateEndOf(dayjs, value, processDescription, options.timeZone)
		case DATE_FORMAT:
			return processDateFormat(dayjs, value, processDescription, options)
		case NUMBER_FORMAT:
			return processNumberFormat(value, processDescription, options)

		case DURATION_FORMAT:
			return processDurationFormat(dayjs, value, processDescription, options)
		case DURATION_ADD:
			return p_processDurationAdd(dayjs, value, processDescription, options)
		case DURATION_SUBTRACT:
			return p_processDurationSubtract(dayjs, value, processDescription, options)

		case INVERT_VALUE:
			return !value

		case INTEGER_ADD:
			return p_processIntegerAdd(value, processDescription, options)
		case INTEGER_SUBTRACT:
			return p_processIntegerSubtract(value, processDescription, options)
		case FLOAT_ADD:
			return p_processFloatAdd(value, processDescription, options)
		case FLOAT_SUBTRACT:
			return p_processFloatSubtract(value, processDescription, options)

		case ADD_CONTEXT:
			return p_addContext(value, options)
		case REMOVE_CONTEXT:
			return p_removeContext(value, options)
		case ADD_SELECTED:
			return p_addSelected(value, options)
		case REMOVE_SELECTED:
			return p_removeSelected(value, options)

		case ADD_SELECTION:
		case REFERENCE_ADD_SELECTION:
			return p_addSelection(value, processDescription, options)
		case REMOVE_SELECTION:
		case REFERENCE_REMOVE_SELECTION:
			return p_removeSelection(value, processDescription, options)

		case REFERENCE_ADD_CONTEXT:
			return p_referenceAddContext(value, options)
		case REFERENCE_REMOVE_CONTEXT:
			return p_referenceRemoveContext(value, options)
		case REFERENCE_ADD_SELECTED:
			return p_referenceAddSelected(value, options)
		case REFERENCE_REMOVE_SELECTED:
			return p_referenceRemoveSelected(value, options)
	}
}

class ValueProcessor {
	constructor(dayjs) {
		this.dayjs = dayjs
	}

	process(value, valueProcessorDescription, options = {}) {
		return valueProcessorDescription.reduce(singleValueProcessor.bind(this, this.dayjs, options), value)
	}

	async p_process(value, valueProcessorDescription, options = {}) {
		const valueProcessor = p_singleValueProcessor.bind(this, this.dayjs, options)
		const processorPromises = valueProcessorDescription.map(
			(descriptionItem) => (result) => valueProcessor(result, descriptionItem)
		)

		const initialPromise = processorPromises.shift()
		const result = await processorPromises.reduce(
			(prevPromise, promise) => prevPromise.then((result) => promise(result)),
			initialPromise(value)
		)
		return result
	}
}

module.exports = ValueProcessor
