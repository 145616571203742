const isNil = require('lodash/isNil')
const isArray = require('lodash/isArray')
const isUndefined = require('lodash/isUndefined')

const {
	INTEGER,
	FLOAT,
	DURATION,
	MULTI_ENUM,
	MULTI_REFERENCE,
	STRING,
	ENUM,
	REFERENCE,
	BOOLEAN,
	DATE,
	RICH_TEXT,
} = require('../enums/e_ObjectClassDataType')
const dataTypeParser = require('./dataTypeParser')

/**
 * Util used to resolve default / property values for Create Object, Update Object ..
 */

const sequentialPromiseResolver = (promiseArray) =>
	new Promise((resolve, reject) => {
		if (promiseArray.length === 0) {
			resolve([])
			return
		}

		// Make sure we dont mutate the input
		const promiseArrayCopy = [...promiseArray]
		const initialPromise = promiseArrayCopy.shift()

		const results = []
		promiseArrayCopy
			.reduce((prevPromise, currentPromise) => {
				return prevPromise.then((result) => {
					results.push(result)
					return currentPromise
				})
			}, initialPromise)
			.then((result) => {
				results.push(result)
				resolve(results)
			})
			.catch((err) => reject(err))
	})

/**
 * TODO: #4414 #4818 Skip until cleanup of builtin enums in services / all available builtin enums exists in Services.
 */
const skipEnumValidation = true

const parseValue = ({ valueForParsing, propertyMeta, getEnumeratedType, logger, dayjs }) => {
	let value = valueForParsing
	if (isUndefined(value)) return

	// Extra parsing (only parse data if not nil)
	if (propertyMeta && !isNil(value)) {
		switch (propertyMeta.dataType) {
			case ENUM: {
				if (isArray(value)) {
					logger &&
						logger.warning(`Skipping value ${value}. Got array, expected single value.`, {
							payload: {
								value,
								enumTypeId: propertyMeta.enumTypeId,
								nodeName: propertyMeta.nodeName,
							},
						})
					value = undefined
					break
				}

				if (skipEnumValidation) break
				/** */
				const enumeratedType = getEnumeratedType(propertyMeta.enumTypeId)
				if (!enumeratedType?.valueDict) {
					logger &&
						logger.warning(`Skipping value ${value}. Cannot find Enumerated type.`, {
							payload: {
								value,
								enumTypeId: propertyMeta.enumTypeId,
								nodeName: propertyMeta.nodeName,
								enumeratedType,
							},
						})
					value = undefined
				} else if (!enumeratedType.valueDict[value]) {
					logger &&
						logger.warning(`Skipping value ${value}. Cannot find Enumerated type value.`, {
							payload: {
								value,
								nodeName: propertyMeta.nodeName,
								enumType: enumeratedType.name,
								enumTypeId: enumeratedType.id,
							},
						})

					value = undefined
				}
				/** */
				break
			}
			case MULTI_ENUM: {
				if (!isArray(value)) value = [value]

				if (skipEnumValidation) break
				/** */
				const enumeratedType = getEnumeratedType(propertyMeta.enumTypeId)
				if (!enumeratedType?.valueDict) {
					logger &&
						logger.warning(`Skipping value ${value.join(', ')}. Cannot find Enumerated type.`, {
							payload: {
								value,
								enumTypeId: propertyMeta.enumTypeId,
								nodeName: propertyMeta.nodeName,
							},
						})
					value = undefined
				} else {
					value = value
						.map((item) => {
							if (!enumeratedType.valueDict[item]) {
								logger &&
									logger.warning(`Skipping value ${item}. Cannot find Enumerated type value.`, {
										payload: {
											value: item,
											nodeName: propertyMeta.nodeName,
											enumType: enumeratedType.name,
											enumTypeId: enumeratedType.id,
										},
									})
								return undefined
							} else {
								return item
							}
						})
						.filter((item) => item)
				}
				/** */
				break
			}
			case REFERENCE:
				if (isArray(value)) {
					logger &&
						logger.warning(`Skipping value ${value}. Got array, expected single value.`, {
							payload: {
								value,
								enumTypeId: propertyMeta.enumTypeId,
								nodeName: propertyMeta.nodeName,
							},
						})
					value = undefined
				}
				break

			case MULTI_REFERENCE:
				if (!isArray(value)) value = [value]
				break

			case RICH_TEXT:
				// Return early as Rich Text properties are objects and `dataTypeParser` morphs them into `undefined`
				// TODO: Add Rich Text support to `dataTypeParser`
				return value

			// Parsing done below
			case INTEGER:
			case DURATION:
			case FLOAT:
			case BOOLEAN:
			case DATE:
			case STRING:
				break
		}
	}

	if (isUndefined(value)) return

	if (propertyMeta) {
		// Do the regular parsing
		value = dataTypeParser(value, propertyMeta.dataType, { dayjs })

		if ([INTEGER, FLOAT, DURATION].includes(propertyMeta.dataType)) {
			if (Number.isNaN(value)) value = null
			if (!Number.isFinite(value)) value = null
		}
	}

	return value
}

/**
 * Used by AppController
 */
const resolvePropertyValues = ({
	propertyValues = [],
	propertyDict = {},
	originalObject = {},
	contextData = {},
	getDataFromDataValue,
	getEnumeratedType,
	dayjs,
	logger,
}) => {
	if (!getDataFromDataValue) {
		logger && logger.error('Missing required method getDataFromDataValue')
		return
	}
	if (!getEnumeratedType) {
		logger && logger.error('Missing required method getEnumeratedType')
		return
	}

	const newValues = {}

	propertyValues.forEach((propertyValue) => {
		const value = getDataFromDataValue(propertyValue.value, contextData, {
			selfObject: { ...originalObject, ...newValues },
		})
		const propertyMeta = propertyDict[propertyValue.propertyId] || propertyDict[propertyValue.nodeName]

		const parsedValue = parseValue({ valueForParsing: value, propertyMeta, getEnumeratedType, logger, dayjs })

		if (!isUndefined(parsedValue)) newValues[propertyValue.nodeName] = parsedValue
	})

	return newValues
}

/**
 * Used by DataController
 */
const p_resolvePropertyValues = async ({
	propertyValues = [],
	propertyDict = {},
	originalObject = {},
	contextData = {},
	p_getDataFromDataValue,
	getEnumeratedType,
	dayjs,
	logger,
}) => {
	if (!p_getDataFromDataValue) {
		logger && logger.error('Missing required method getDataFromDataValue')
		return
	}
	if (!getEnumeratedType) {
		logger && logger.error('Missing required method getEnumeratedType')
		return
	}

	// Transform method to take inn params used in AppController
	const getEnumeratedTypeForUse = (id) => getEnumeratedType({ enumeratedTypeId: id })

	const newValues = {}

	const promiseList = propertyValues.map(async (propertyValue) => {
		const value = await p_getDataFromDataValue(propertyValue.value, contextData, {
			selfObject: { ...originalObject, ...newValues },
		})
		const propertyMeta = propertyDict[propertyValue.propertyId] || propertyDict[propertyValue.nodeName]

		const parsedValue = parseValue({
			valueForParsing: value,
			propertyMeta,
			getEnumeratedType: getEnumeratedTypeForUse,
			logger,
			dayjs,
		})

		if (!isUndefined(parsedValue)) newValues[propertyValue.nodeName] = parsedValue
	})
	await sequentialPromiseResolver(promiseList)

	return newValues
}

module.exports = { resolvePropertyValues, p_resolvePropertyValues, parseValue }
